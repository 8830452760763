.pseudotabs {
  font-size: 16px;
  padding: 8px;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: stretch;

  .tablink {
    flex-grow: 1;
    background-color: #fff;
    color: #999;
    float: left;
    border: 1px solid #ccc;
    border-right: none;
    cursor: pointer;
    padding: 10px;
    transition: 0.3s;

    &:hover {
      color: black;
    }

    &.selected {
      background-color: #f0e2ff;
      color: black;
    }
  }
}

.pseudoLink {
  color: #0000ee;
  cursor: pointer;
}

.indentedDiv {
  margin-left: 20px;
}

.buyboxFragment {
  :last-child {
    margin-bottom: 8px;
  }
}

.sectionLabel {
  margin-top: 8px;
  margin-bottom: 8px;
}

.addPaymentRowCard {
  padding: 8px;
  margin: 16px 4px 16px;
}

table {
  border-collapse: collapse;
  vertical-align: middle;
  width: 100%;
  td {
    border: 1px solid black;
  }
}
